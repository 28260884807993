import { CheckboxProps } from '@finn/design-system';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React from 'react';

import Checkbox from './';

// interface can not extend a union-type
type Props = CheckboxProps & {
  label?: string;
  alignment?: string;
  useLargeFont?: boolean;
};

interface IOuterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
}

const FormikCheckbox: React.FunctionComponent<
  Omit<Props & IOuterProps, 'onChange'>
> = ({ formik, label, value, ...rest }) => {
  const id = rest.id || rest.name || '';
  const error = get(formik, `errors.${id}`, false);
  const touched = get(formik, `touched.${id}`, false);
  const hasError = touched && error;
  const checked = !!get(formik, `values.${id}`, false);
  const track = useTrackingStore((state) => state.track);

  const handleChange = (isChecked: boolean) => {
    track(TrackingEventName.CHECKBOX_CLICKED, {
      fieldName: id,
      additionalProps: { action: isChecked ? 'checked' : 'unchecked' },
    });
    formik.setFieldValue(id, isChecked);
    formik.setFieldError(id, '');
  };

  const isChecked = (value ? value : checked) as boolean;

  return (
    <div className="flex items-start">
      <Checkbox
        id={id}
        checked={isChecked}
        error={hasError}
        onCheckedChange={handleChange}
        name={id}
        onBlur={(e) => {
          formik.setFieldTouched(id);
          formik.handleBlur(e);
        }}
      />
      <label
        className={cn('body-14-light ml-2', {
          'text-red': hasError,
          'text-black': !hasError,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<Props, any>(FormikCheckbox);
