import { Button, Input, InputProps } from '@finn/design-system';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { useIntl } from 'react-intl';

import { B2BContactFormContent } from '~/types/cosmicModules';
import { getInputPlaceholder } from '~/utils/cosmic';

import SelectDropdown from '../FormElements/SelectDropdown';
import TextArea from '../FormElements/TextArea';
import { ContactValues } from './config';

const options = [
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
];

const useStyles = makeStyles((theme: Theme) => ({
  phoneInput: {
    paddingTop: 0,
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

// TODO we should kill this as soon as formik is dead
export const FormikInput = connect<InputProps>(
  ({ formik, inputRef, containerRef, name, onBlur, onChange, ...props }) => {
    const i18n = useIntl();
    // for legacy reasons get from lodash is used
    const wasSubmitted = formik?.submitCount > 0;
    const value = get(formik?.values, name, '');
    const touched = get(formik?.touched, name, false);
    const error = (touched || wasSubmitted) && get(formik?.errors, name);
    const errorMessage = error && i18n.formatMessage({ id: error });
    const track = useTrackingStore((state) => state.track);

    return (
      <Input
        name={name}
        containerRef={containerRef}
        ref={inputRef}
        error={errorMessage}
        value={value}
        onChange={(e) => {
          formik?.setFieldValue?.(name, e?.target?.value || e?.phone);
          track(TrackingEventName.FORM_FIELD_ENTERED, {
            fieldName: name,
            once: true,
          });
          onChange?.(e);
        }}
        onBlur={(e) => {
          formik?.handleBlur?.(e);
          onBlur?.(e);
        }}
        {...props}
      />
    );
  }
);

interface IProps {
  formik: FormikProps<ContactValues>;
  loading: boolean;
  cosmicContent: B2BContactFormContent;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicContent,
}) => {
  const i18n = useIntl();
  const classes = useStyles();
  const {
    metadata: {
      input_fields: { phone_number_format },
      submit_button: { button_label },
    },
  } = cosmicContent;

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <SelectDropdown
            name="salutation"
            placeholder={getInputPlaceholder(cosmicContent, 'salutation')}
            options={options}
            className="!bg-white"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            name="company"
            label={getInputPlaceholder(cosmicContent, 'company')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            name="firstName"
            label={i18n.formatMessage({ id: 'b2b.firstName' })}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            name="lastName"
            label={getInputPlaceholder(cosmicContent, 'last_name')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicContent, 'email_address')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            type="tel"
            name="phoneNumber"
            label={getInputPlaceholder(cosmicContent, 'phone_number')}
            placeholder={phone_number_format}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikInput
            name="fleetSize"
            label={getInputPlaceholder(cosmicContent, 'fleet_size')}
            type="number"
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextArea
            name="message"
            placeholder={getInputPlaceholder(cosmicContent, 'message')}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              className="w-full"
              loading={loading}
            >
              {button_label}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
export default Form;
